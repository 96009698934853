.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.button:hover,
.button:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.area--one .unitOne {
  width: calc((100% / 3) - 30px);
}
.area--two .unitThree .part {
  width: calc(25% - 30px);
}
/*# sourceMappingURL=./screen-large.css.map */